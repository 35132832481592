import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { SystemService } from '../../system/system.service';


import * as Parse from 'parse';
import * as mixpanel from 'mixpanel-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  listings = [];
  noMoreListing = false;
  previousListings = [];
  noMorePreviousListing = false;
  loading = true;
  error = false;
  count = 0;
  previousCount = 0;
  limit = 100;

  search_query = null;
  search_filter = 'buy';

  constructor(
    private route: ActivatedRoute,
    private title: Title,
    private meta: Meta,
    public system: SystemService,
    private router: Router
  ) {
    Parse.initialize(environment.PARSE_APP_ID, environment.PARSE_JS_KEY);
    (Parse as any).serverURL = environment.serverURL;

    this.route.queryParams.subscribe(params => {
      if (typeof params.filter !== 'undefined') {
        this.search_filter = params.filter;
        this.getListings();

      } else {
        this.getListings();
      }
      this.getPreviousListings();
    });
    mixpanel.init(environment.MIXPANEL_TOKEN);
  }

  ngOnInit() {
    this.title.setTitle('FarmFirst Trusted Wholesale Marketplace');
    this.meta.removeTag('name="description"');
    this.meta.removeTag('name="keywords"');

    this.meta.addTags([
      {
        name: 'description', content: 'FarmFirst Agro Trade, Trusted Wholesale Marketplace for Agro Commodities.'
      },
      {
        name: 'keywords', content: 'FirmFirst, March 2015, LLP Registred, for wholesale buyers, sellers, traders, '
          + 'exporters, importers, agents, suppliers and farmers. Freshly hand-picked, assured quality, Price guarantee, delivery'
          + 'Onions, Potatoes, Rice, Wheat, Cereals, Lemon, Seeds and Plants, Fruits and Fresh Vegetables,'
          + 'Organics, exotic English vegetables, Cashew, nuts, dry fruits, Curry leaves, Banana, Green Chilies, All'
          + 'spices, Coconut, Sugar, edible and essential Oils, Eggs, Maize, seedlings, flowers, plants and more'
      }
    ]);
  }

  onFilterChange() {

    this.count = 0;
    this.previousCount = 0;
    this.listings = [];
    this.previousListings = [];
    this.noMoreListing = false;
    this.noMorePreviousListing = false;
    this.getListings();
  }


  getListings() {
    if (this.noMoreListing === false) {
      this.loading = true;
      this.error = false;

      const listing = Parse.Object.extend('ff_newlisting');
      const query = new Parse.Query(listing);

      if (this.search_filter === 'buy') {
        query.equalTo('list_type', 'B');
      } else if (this.search_filter === 'sell') {
        query.equalTo('list_type', 'S');
      }else if (this.search_filter === 'tender') {
        query.equalTo('list_type', 'T');
      }

      if (this.search_query) {
        mixpanel.track(environment.MIXPANEL_TRACK_PREFIX + 'Searched');
        this.search_filter = 'all';
        query.matches('list_title', this.search_query, 'i');
      }
      query.equalTo('active', true);
      query.descending('createdAt');
      // debugger; 
      query.limit(this.limit);
      query.skip(this.count * this.limit);
      query.find().then((results) => {
        if(results.length === 0) {
          this.noMoreListing = true;
        }
        this.listings = this.listings.concat(results);
        this.loading = false;
        this.error = false;
        console.log(results);
      }, (error) => {
        this.loading = false;
        this.error = true;

        console.log(error);
      });
      this.count++;
    }

  }

  getPreviousListings() {
    if (this.noMorePreviousListing === false) {
      this.loading = true;
      this.error = false;

      const listing = Parse.Object.extend('ff_newlisting');
      const query = new Parse.Query(listing);

      if (this.search_filter === 'buy') {
        query.equalTo('list_type', 'B');
      } else if (this.search_filter === 'sell') {
        query.equalTo('list_type', 'S');
      }else if (this.search_filter === 'tender') {
        query.equalTo('list_type', 'T');
      }

      if (this.search_query) {
        mixpanel.track(environment.MIXPANEL_TRACK_PREFIX + 'Searched');
        this.search_filter = 'all';
        query.matches('list_title', this.search_query, 'i');
      }
      query.equalTo('active', false);
      // query.greaterThan('createdAt', '2019-01-01');
      query.notEqualTo('list_freq', 'O');
      query.equalTo('pushed', true);
      query.descending('createdAt');
      // debugger; 
      query.limit(this.limit);
      query.skip(this.previousCount * this.limit);
      query.find().then((results) => {
        if(results.length === 0) {
          this.noMorePreviousListing = true;
        }
        this.previousListings = this.previousListings.concat(results);
        this.loading = false;
        this.error = false;
        console.log(results);
      }, (error) => {
        this.loading = false;
        this.error = true;

        console.log(error);
      });
      this.previousCount++;
    }

  }

  searchListing() {
    if (this.search_filter === 'sell') {
      this.router.navigate(
        ['products/sell/' + this.system.seo_url_generate('', this.search_query, '')]
      );
    } else if (this.search_filter === 'buy') {
      this.router.navigate(
        ['products/buy/' + this.system.seo_url_generate('', this.search_query, '')]
      );
    } else {
      this.router.navigate(
        ['products/all/' + this.system.seo_url_generate('', this.search_query, '')]
      );
    }
  }
}
