import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { CookieService } from 'ngx-cookie-service';
import { SystemService } from '../../system/system.service';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss']
})
export class DeleteComponent implements OnInit {

  constructor(private http: Http, public system: SystemService, private cookie: CookieService, public router: Router,) { }

  ngOnInit() {
  }

  deleteAccount() {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json; charset=utf-8');
    headers.append('X-Parse-Application-Id', environment.PARSE_APP_ID);
    headers.append('X-Parse-REST-API-Key', environment.PARSE_REST_API_KEY);

    this.http.post(this.system.generateRequestUrl('/classes/ff_delete_requests', null, environment.serverURL),
      JSON.stringify({
        user: { __type: 'Pointer', className: '_User', objectId: this.cookie.get('user') },
      }), { headers: headers })
      .map(res => res.json()).subscribe(response => {
        alert(`Account delete request received. We will process your request within 15 days.`);
        this.router.navigate(['/']);
      }, error => {
        alert(`Fail to delete account! please try again after sometime.`);
      });
  }

}
