import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { Meta, Title } from '@angular/platform-browser';

import { environment } from '../../../environments/environment';
import { SystemService } from '../../system/system.service';

import { CookieService } from 'ngx-cookie-service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import * as Parse from 'parse';
import * as mixpanel from 'mixpanel-browser';
import { Type } from '@angular/compiler';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  item = [];
  loading = true;
  error = false;

  similarItems = [];
  nextItem = null;
  previousItem = null;

  user_id = null;
  subscription = false;
  contactViews = 0;
  contactView = false;

  listedBy = null;
  listing_id = null;

  jsonLD: SafeHtml;
  closeResult: string;


  user = {
    objectId: null,
    name: null,
    mobile: null,
    email: null,
    city: null,
    state: null,
    country: null,
    pin: null,
    id_proof: null,
    address_proof: null,
    bus_proof: null,
    user_type: null,
  };

  offer = {
    list_id: null,
    bidder_id: null,
    o_qty: null,
    o_price: null,
    o_date_of_delivery: null,
    o_farm_address: null,
    o_comments: null,
    o_submitted_date: null,
    o_status: 'S'
  }

  measures: any = null;
  country: any = null;
  state: any = null;
  district: any = null;
  tender: any;
  tenderMeasure: any;
  errorMin: boolean = false;
  countries: any[] = [];
  states: any[] = [];
  cities: any[] = [];
  authToken: any;


  constructor(
    private http: Http,
    public system: SystemService,
    private route: ActivatedRoute,
    private router: Router,
    private title: Title,
    private meta: Meta,
    private cookie: CookieService,
    private sanitizer: DomSanitizer,
    private modalService: NgbModal
  ) {
    Parse.initialize(environment.PARSE_APP_ID, environment.PARSE_JS_KEY, 'dfsfsd');
    (Parse as any).serverURL = environment.serverURL;
    this.user_id = this.cookie.get('user');
    if (this.user_id) {
      this.getSubscription();
    }
    console.log('user', this.user_id);
    mixpanel.init(environment.MIXPANEL_TOKEN);

  }

  ngOnInit() {
    this.getToken();
    this.getUser();
    this.route.queryParams.subscribe(params => {
      if (typeof params.id !== 'undefined') {
        this.listing_id = params.id;
        this.getItem();
      } else {
        const current_url = this.route.snapshot.url;
        const current_path = current_url[current_url.length - 1].path;
        console.log(current_path);

        const split = current_path.split('-');
        console.log(split);

        this.listing_id = split[split.length - 1].replace('.html', '');
        console.log(this.listing_id);
        this.getItem();

      }
    });
  }


  getToken() {
    const headers = new Headers();

    headers.append('Accept', 'application/json');
    headers.append('api-token', 'G9Sx7YqMaN8GcJ7HfELgEkb5o0e0BNn5V9FJBacCYK-yJjqpLn4eyTs0oMGLZMSIDzc');
    headers.append('user-email', 'ananthugvr@gmail.com');


    this.http.get('https://www.universal-tutorial.com/api/getaccesstoken', { headers: headers })
      .subscribe((response: any) => {
        let token = JSON.parse(response._body);
        this.authToken = token.auth_token;
        console.log('auth token', this.authToken);
        this.getCountry();
      });
  }

  getCountry() {
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Authorization', 'Bearer ' + this.authToken);

    this.http.get('https://www.universal-tutorial.com/api/countries/', { headers: headers })
      .subscribe((response: any) => {

        let country = JSON.parse(response._body)
        this.countries = country;
        console.log(this.countries);
      });
  }
  countrySelected() {
    console.log('country selected');
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Authorization', 'Bearer ' + this.authToken);

    this.http.get('https://www.universal-tutorial.com/api/states/' + this.country, { headers: headers })
      .subscribe((response: any) => {

        let state = JSON.parse(response._body)
        this.states = state;
        console.log(this.states);
      });
  }
  StateSelected() {
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Authorization', 'Bearer ' + this.authToken);

    this.http.get('https://www.universal-tutorial.com/api/cities/' + this.state, { headers: headers })
      .subscribe((response: any) => {

        let city = JSON.parse(response._body)
        this.cities = city;
        console.log(this.cities);
      });
  }

  getUser() {
    console.log('called grt user', this.cookie.get('phoneNumber'));

    if (this.cookie.get('user')) {
      const headers = new Headers();

      headers.append('X-Parse-Application-Id', environment.PARSE_APP_ID);
      headers.append('X-Parse-REST-API-Key', environment.PARSE_REST_API_KEY);

      this.http.get(this.system.generateRequestUrl('/classes/_User/' + this.cookie.get('user'),
        {}, environment.serverURL), { headers: headers })
        .map(res => res.json()).subscribe(response => {
          console.log(response);
          this.user = response;
          console.log('user data', this.user);

        });
    }
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      console.log(this.closeResult);
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  openConfirm(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-confirm', size: 'lg' }).result.then((result) => {
      this.closeResult = `Closed ${result}`;
      console.log(this.closeResult, '===', 'Closed save');

      if (this.closeResult === 'Closed save') {
        console.log('save');
        this.makeOffer();
        this.modalService.dismissAll();
      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      console.log(this.closeResult);

    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  makeOffer() {

    this.offer.list_id = this.item[0].attributes.list_id;
    this.offer.bidder_id = { __type: 'Pointer', className: '_User', objectId: this.user.objectId }
    this.offer.o_qty = this.offer.o_qty + ' ' + this.measures;
    this.offer.o_farm_address = this.offer.o_farm_address + ',' + this.country + ',' + this.state + ',' + this.district;
    this.offer.o_submitted_date = this.getDate();
    console.log(this.offer);

    const headers = new Headers();
    headers.append('Content-Type', 'application/json; charset=utf-8');
    headers.append('X-Parse-Application-Id', environment.PARSE_APP_ID);
    headers.append('X-Parse-REST-API-Key', environment.PARSE_REST_API_KEY);

    this.http.post(this.system.generateRequestUrl('/classes/ff_offers', null, environment.serverURL),
      JSON.stringify(this.offer), { headers: headers })
      .map(res => res.json()).subscribe(response => {
        console.log(response);

      }, error => {

      });

  }

  getDate() {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();

    let day = yyyy + '-' + mm + '-' + dd;
    return day;
  }


  getItem(search = null) {
    this.loading = true;
    this.error = false;

    const listing = Parse.Object.extend('ff_newlisting');

    const query = new Parse.Query(listing);
    if (search) {
      query.matches('list_title', search, 'i');
    }
    // query.equalTo('active', true);
    query.equalTo('objectId', this.listing_id);
    query.descending('createdAt');
    query.find().then(async (results) => {
      this.loading = false;
      this.error = false;
      this.item = results;
      this.getSimilarItems();
      this.nextListing();
      this.previousListing();


      let meta_desc;
      let meta_keywords;

      if (this.item[0].attributes.list_type === 'B') {

        // ----------------------------------------- Meta Description & Title

        this.title.setTitle('Wanted ' + this.item[0].attributes.list_title + ' ' + this.item[0].attributes.list_qty + ' Wholesale rate, Todays Price ' + this.item[0].attributes.list_price + ' ,' + this.item[0].attributes.list_loc);
        meta_desc = 'Wanted '
          + this.item[0].attributes.list_title
          + ' From ' + this.item[0].attributes.list_loc
          + ', ' + this.item[0].attributes.list_title + ' Price Today '
          + this.item[0].attributes.list_qty
          + ' Contact details and address of Buyer ' + this.item[0].attributes.list_name + ', ' + this.item[0].attributes.list_loc;

        // ----------------------------------------- Meta Keywords

        meta_keywords = this.item[0].attributes.list_title
          + ' Import'
          + ', ' + this.item[0].attributes.list_title + ' Wholesale '
          + ', ' + this.item[0].attributes.list_title + ' Farmer'
          + ', ' + this.item[0].attributes.list_title + ' Farmer Producer Company'
          + ', ' + this.item[0].attributes.list_loc + ' ' + this.item[0].attributes.list_title
          + ', ' + this.item[0].attributes.list_title + ' Buyer'
          + ', ' + this.item[0].attributes.list_title + ' Price Today'
          + ', ' + this.item[0].attributes.list_title + ' Price per kg'
          + ', ' + this.item[0].attributes.list_desc
          + ', ' + this.item[0].attributes.list_title + ' Cash on Delivery'
          + ', ' + this.item[0].attributes.list_title + ' Credit Sale'
          + ', ' + this.item[0].attributes.list_name
          + ', ' + this.item[0].attributes.list_title + ' Spot Rate'
          + ', ' + this.item[0].attributes.list_title + ' Mandi Rate';

      }
      if (this.item[0].attributes.list_type === 'S') {

        // ----------------------------------------- Meta Description & Title

        this.title.setTitle('For Sale '
          + this.item[0].attributes.list_title + ' '
          + this.item[0].attributes.list_qty + ' Wholesale rate, Todays Price '
          + this.item[0].attributes.list_price + ' ,'
          + this.item[0].attributes.list_loc);
        meta_desc = 'For Sale '
          + this.item[0].attributes.list_title
          + ' From ' + this.item[0].attributes.list_loc
          + ', ' + this.item[0].attributes.list_title + ' Price Today '
          + this.item[0].attributes.list_qty
          + 'Contact details and address of Seller' + this.item[0].attributes.list_name +','+ this.item[0].attributes.list_loc;

        // ----------------------------------------- Meta Keywords

        meta_keywords = this.item[0].attributes.list_title
          + ' Export'
          + ', ' + this.item[0].attributes.list_title + ' Wholesale '
          + ', ' + this.item[0].attributes.list_title + ' Farmer'
          + ', ' + this.item[0].attributes.list_title + ' Farmer Producer Company'
          + ', ' + this.item[0].attributes.list_loc + ' ' + this.item[0].attributes.list_title
          + ', ' + this.item[0].attributes.list_title + ' Seller'
          + ', ' + this.item[0].attributes.list_title + ' Price Today'
          + ', ' + this.item[0].attributes.list_title + ' Price per kg'
          + ', ' + this.item[0].attributes.list_desc
          + ', ' + this.item[0].attributes.list_title + ' Cash on Delivery'
          + ', ' + this.item[0].attributes.list_title + ' Credit Sale'
          + ', ' + this.item[0].attributes.list_name
          + ', ' + this.item[0].attributes.list_title + ' Spot Rate'
          + ', ' + this.item[0].attributes.list_title + ' Mandi Rate'
          + ', ' + this.item[0].attributes.list_title + ' Online APMC';

      }
      if (this.item[0].attributes.list_type === 'T') {

        // ----------------------------------------- Meta Description & Title

        this.title.setTitle(' ' + this.item[0].attributes.list_title
          + ' ' + this.item[0].attributes.list_qty
          + ' Wholesale rate, Todays Price ' + this.item[0].attributes.list_price
          + ' ,' + this.item[0].attributes.list_loc);
        meta_desc = ' '
          + this.item[0].attributes.list_title
          + ' From ' + this.item[0].attributes.list_loc
          + ', ' + this.item[0].attributes.list_title + ' Price Today '
          + this.item[0].attributes.list_qty;
        // + "Contact details and address of Seller /Buyer"
      }

      this.meta.removeTag('name="description"');
      this.meta.removeTag('name="keywords"');
      console.log(meta_desc, 'Meta Desc');

      this.meta.addTags([
        {
          name: 'description', content: meta_desc
        },
        {
          name: 'keywords', content: meta_keywords

          // this.item[0].attributes.list_title
          //   + ', ' + this.system.seo_keyword_generate(this.item[0].attributes.list_desc)
          //   + ', ' + this.system.seo_keyword_generate(this.item[0].attributes.list_loc)
          //   + ', ' + this.system.seo_keyword_generate(this.item[0].attributes.list_name)
        },
        {
          name: 'title', content: this.system.seo_url_generate(
            this.item[0].attributes.list_type,
            this.item[0].attributes.list_title,
            this.item[0].attributes.list_loc,
            '', ' '
          )
        }
      ]);


      const headers = new Headers();

      headers.append('X-Parse-Application-Id', environment.PARSE_APP_ID);
      headers.append('X-Parse-REST-API-Key', environment.PARSE_REST_API_KEY);

      this.http.get(this.system.generateRequestUrl('/classes/_User/' + this.item[0].attributes.listedBy.id,
        {}, environment.serverURL), { headers: headers })
        .map(res => res.json()).subscribe(response => {
          console.log(response, 'User_');
          this.listedBy = response;
        });

      let currency = 'INR';
      if (this.item[0].attributes.currency === '₹') {
        currency = 'INR';
      } else if (this.item[0].attributes.currency === '$') {
        currency = 'USD';
      }
      let price = '0';
      if (this.item[0].attributes.list_price) {
        price = this.item[0].attributes.list_price.match(/[+-]?\d+(\.\d+)?/g);
        if (price && typeof price !== 'undefined' && price.length > 0) {
          price = price[0];
        } else {
          price = '0';
        }
      }

      if (this.item[0].attributes.list_type === 'T') {
        console.log('item()=>', this.item[0].attributes);

        this.getTender(this.item[0].attributes.list_id);
        let qty = this.item[0].attributes.list_qty;
        let split = qty.split(' ');
        this.tenderMeasure = split[1];
        this.measures = this.tenderMeasure;


      }

      const createDate = new Date(this.item[0].attributes.createdAt);
      let priceValidDate = createDate;
      priceValidDate.setDate(createDate.getDate() + 15);
      this.jsonLD = {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        'name': this.system.seo_url_generate(
          this.item[0].attributes.list_type,
          this.item[0].attributes.list_title,
          this.item[0].attributes.list_loc,
          '', ' '
        ),
        'sku': this.item[0].attributes.id,
        'productID': this.item[0].attributes.id,
        'mpn': '0',
        'gstn': '08',
        'brand': {
          '@type': 'Thing',
          'name': this.item[0].attributes.list_type,
        },
        'image': [
          this.item[0].attributes.list_img ? 'https://' + this.item[0].attributes.list_img._url :
            'https://' + environment.APP_URL + '/assets/images/ic_cat_' + this.item[0].attributes.list_category.toLowerCase() + '.png'],
        'description': this.item[0].attributes.list_title
          + ', ' + this.item[0].attributes.list_desc
          + ' at ' + this.item[0].attributes.list_loc
          + ' available quantity ' + this.item[0].attributes.list_qty
          + ' with rate of ' + this.item[0].attributes.list_price
          + ' and published by ' + this.item[0].attributes.list_name,
        'category': this.item[0].attributes.list_category,
        'url': 'https://' + environment.APP_URL + '/product/' + this.system.seo_url_generate(
          this.item[0].attributes.list_type,
          this.item[0].attributes.list_title,
          this.item[0].attributes.list_loc + ' ' + this.item[0].id
        ),
        'aggregateRating': {
          '@type': 'AggregateRating',
          'ratingValue': '4.8',
          'reviewCount': '100'
        },
        'offers': {
          '@Type': 'Offer',
          'priceCurrency': currency,
          'price': price,
          'availability': 'InStock',
          'priceValidUntil': priceValidDate,
          'url': 'https://' + environment.APP_URL + '/product/' + this.system.seo_url_generate(
            this.item[0].attributes.list_type,
            this.item[0].attributes.list_title,
            this.item[0].attributes.list_loc + ' ' + this.item[0].id
          ),
          'image': this.item[0].attributes.list_img ? 'https://' + this.item[0].attributes.list_img._url :
            'https://' + environment.APP_URL + '/assets/images/ic_cat_' + this.item[0].attributes.list_category.toLowerCase() + '.png',
          'category': this.item[0].attributes.list_category
        }
      };

      this.getSafeHTML(this.jsonLD);
    }, async (error) => {
      this.loading = false;
      this.error = true;

      console.log(error);
    });



  }

  getSimilarItems() {

    const listing = Parse.Object.extend('ff_newlisting');

    const query = new Parse.Query(listing);

    // query.equalTo('active', true);
    query.equalTo('list_category', this.item[0].attributes.list_category);
    query.equalTo('list_type', this.item[0].attributes.list_type);
    query.equalTo('active', true);

    query.descending('createdAt');
    query.limit(4);
    query.find().then(async (results) => {
      this.similarItems = results;
    });
  }

  nextListing() {
    const listing = Parse.Object.extend('ff_newlisting');
    const query = new Parse.Query(listing);

    query.lessThan('createdAt', this.item[0].attributes.createdAt);
    query.equalTo('list_type', this.item[0].attributes.list_type);

    query.equalTo('active', true);
    query.descending('createdAt');
    query.limit(1);
    query.find().then((results: any) => {
      if (results.length > 0) {
        this.nextItem = results[0];
      } else {
        this.nextItem = null;
      }
    }, (error) => {

    });
  }

  previousListing() {
    const listing = Parse.Object.extend('ff_newlisting');
    const query = new Parse.Query(listing);

    query.greaterThan('createdAt', this.item[0].attributes.createdAt);
    query.equalTo('list_type', this.item[0].attributes.list_type);

    query.equalTo('active', true);
    query.ascending('createdAt');
    query.limit(1);
    query.find().then((results: any) => {
      if (results.length > 0) {
        this.previousItem = results[0];
      } else {
        this.previousItem = null;
      }
    }, (error) => {

    });
  }

  checkMinimum() {
    console.log('minimum');
    if (this.offer.o_qty < this.tender.t_min_qty) {
      this.errorMin = true;
    }
    else {
      this.errorMin = false;
    }

  }

  getTender(id) {
    console.log('tender()=>list_id', id);

    const tender = Parse.Object.extend('ff_tenders');
    const queryTender = new Parse.Query(tender);
    queryTender.equalTo('list_id', id);
    queryTender.find().then((res: any) => {
      console.log('tenderrrrrrrr', res[0].attributes);
      this.tender = res[0].attributes;
    });

  }


  editPrice() {
    const newPrice = prompt('Enter your new price', this.item[0].attributes.list_price);
    console.log(this.item[0].attributes.list_price);
    console.log(newPrice);
    if (newPrice !== null) {
      this.reList(newPrice);
    }
  }

  reList(newPrice = null) {
    const product = {
      currency: this.item[0].attributes.currency,
      list_category: this.item[0].attributes.list_category,
      list_desc: this.item[0].attributes.list_desc,
      list_email: this.item[0].attributes.list_email,
      list_freq: this.item[0].attributes.list_freq,
      list_img: this.item[0].attributes.list_img ?
        { __type: 'File', name: this.item[0].attributes.list_img._name, url: this.item[0].attributes.list_img._url } :
        this.item[0].attributes.list_img,
      list_loc: this.item[0].attributes.list_loc,
      list_mobile: this.item[0].attributes.list_mobile,
      list_name: this.item[0].attributes.list_name,
      list_price: newPrice ? newPrice : this.item[0].attributes.list_price,
      list_qty: this.item[0].attributes.list_qty,
      list_title: this.item[0].attributes.list_title,
      list_type: this.item[0].attributes.list_type,
      listedBy: { __type: 'Pointer', className: '_User', objectId: this.item[0].attributes.listedBy.id }
    };

    const headers = new Headers();
    headers.append('Content-Type', 'application/json; charset=utf-8');
    headers.append('X-Parse-Application-Id', environment.PARSE_APP_ID);
    headers.append('X-Parse-REST-API-Key', environment.PARSE_REST_API_KEY);

    this.http.put(this.system.generateRequestUrl('/classes/ff_newlisting/' + this.item[0].id, null, environment.serverURL),
      JSON.stringify(product), { headers: headers })
      .map(res => res.json()).subscribe(response => {
        console.log(response);
        alert('Thank you for updating your listing. Please wait while your request is being processed!');
        this.getItem();
      }, error => {

      });
  }

  deactivate() {
    const action = confirm('Once you deactivate you can not reactivate the listing.');
    if (action === true) {
      const product = {
        active: false
      };

      const headers = new Headers();
      headers.append('Content-Type', 'application/json; charset=utf-8');
      headers.append('X-Parse-Application-Id', environment.PARSE_APP_ID);
      headers.append('X-Parse-REST-API-Key', environment.PARSE_REST_API_KEY);

      this.http.put(this.system.generateRequestUrl('/classes/ff_newlisting/' + this.item[0].id, null, environment.serverURL),
        JSON.stringify(product), { headers: headers })
        .map(res => res.json()).subscribe(response => {
          console.log(response);
          alert('Listing Deactivated!');
          this.router.navigate(['account/profile']);
        }, error => {
          alert('Something went wrong, please try again later.');

        });
    }
  }

  getSubscription() {

    const headers = new Headers();
    headers.append('X-Parse-Application-Id', environment.PARSE_APP_ID);
    headers.append('X-Parse-REST-API-Key', environment.PARSE_REST_API_KEY);

    this.http.get(this.system.generateRequestUrl('/classes/_User/' + this.cookie.get('user'),
      {}, environment.serverURL), { headers: headers })
      .map(res => res.json()).subscribe(response => {
        if (this.cookie.get('count')) {
          this.contactViews = parseFloat(this.cookie.get('count'));
        } else {
          this.cookie.set(
            'count',
            '0',
            new Date(new Date().setDate(new Date().getDate() + 365)),
            '/',
            '.' + environment.APP_URL,
            environment.COOKIE_SECURE,
            'Lax'
          );
        }

        if (response.subs_end && response.subs_end > this.system.dayOfYear()) {
          this.subscription = true;
        } else {
          this.subscription = false;
        }
      });

  }

  accessContactView() {
    mixpanel.track(environment.MIXPANEL_TRACK_PREFIX + 'Contact Viewed');

    const nextCount = parseFloat(this.cookie.get('count')) + 1;
    this.contactViews = nextCount;
    this.cookie.set(
      'count',
      nextCount.toString(),
      new Date(new Date().setDate(new Date().getDate() + 365)),
      '/',
      '.' + environment.APP_URL,
      environment.COOKIE_SECURE,
      'Lax'
    );
    this.contactView = true;
    /**
     * Disabled: due to error that customer subscription update via backed
     * still user is unsubscribed
     */
    // if (nextCount > 4) {
    //   const headers = new Headers();
    //   headers.append('Content-Type', 'application/json; charset=utf-8');
    //   headers.append('X-Parse-Application-Id', environment.PARSE_APP_ID);
    //   headers.append('X-Parse-REST-API-Key', environment.PARSE_REST_API_KEY);

    //   this.http.post(this.system.generateRequestUrl('/functions/UserSubscriptionUpdate', null, environment.serverURL),
    //     JSON.stringify({
    //       user_id: this.user_id,
    //       sub_start: 1,
    //       sub_end: 1,
    //     }), { headers: headers })
    //     .map(res => res.json()).subscribe(response => {
    //       console.log(response);
    //     }, error => {
    //       console.log('Error: Something went wrong, please try again later.');
    //     });
    // }
  }

  async getSafeHTML(value: {}) {
    // If value convert to JSON and escape / to prevent script tag in JSON
    const json = value ? JSON.stringify(value, null, 2).replace(/\//g, '\\/') : '';
    const html = `${json}`;
    this.jsonLD = this.sanitizer.bypassSecurityTrustHtml('<script type="application/ld+json">' + html + '</script>');
  }

  actPhoneCall() {
    mixpanel.track(environment.MIXPANEL_TRACK_PREFIX + 'Called');
  }

  convertLetterToNumber(str) {
    let out = 0;
    const len = str.length;
    for (let pos = 0; pos < len; pos++) {
      out += (str.charCodeAt(pos) - 64) * Math.pow(26, len - pos - 1);
    }
    return out;
  }

  getTs() {
    return new Date().getTime();
  }
}
