import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { SystemService } from '../../system/system.service';
import { Http, Headers } from '@angular/http';

import * as Parse from 'parse';
import * as mixpanel from 'mixpanel-browser';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {

  listings = [];
  loading = true;
  error = false;

  search_query = null;
  search_filter = 'buy';

  count = 0;
  noMoreListing = false;
  previousCount = 0;
  limit = 100;
  

  constructor(
    private route: ActivatedRoute,
    private title: Title,
    private meta: Meta,
    public system: SystemService,
    private http: Http,
    private router: Router,
    private cookie: CookieService,
  ) {
    Parse.initialize(environment.PARSE_APP_ID, environment.PARSE_JS_KEY);
    (Parse as any).serverURL = environment.serverURL;

    mixpanel.init(environment.MIXPANEL_TOKEN);

    if (this.route.snapshot.paramMap.get('type') !== null) {
      this.search_filter = this.route.snapshot.paramMap.get('type');
    } else {
      this.search_filter = 'buy';
    }

    if (this.route.snapshot.paramMap.get('query') !== null) {
      this.search_query = this.query_from_segment(this.route.snapshot.paramMap.get('query'));
      this.addSearchQuery(this.search_query);
      this.getListings();
    } else {
      this.route.queryParams.subscribe(params => {
        if (typeof params.filter !== 'undefined') {
          this.search_filter = params.filter;
          this.getListings();
        } else {
          this.getListings();
        }
      });
    }
  }

  ngOnInit() {
    this.title.setTitle('FarmFirst Trusted Wholesale Marketplace');
    this.meta.removeTag('name="description"');
    this.meta.removeTag('name="keywords"');

    this.meta.addTags([
      {
        name: 'description', content: 'FarmFirst Agro Trade, Trusted Wholesale Marketplace for Agro Commodities.'
      },
      {
        name: 'keywords', content: 'FirmFirst, March 2015, LLP Registred, for wholesale buyers, sellers, traders, '
          + 'exporters, importers, agents, suppliers and farmers. Freshly hand-picked, assured quality, Price guarantee, delivery'
          + 'Onions, Potatoes, Rice, Wheat, Cereals, Lemon, Seeds and Plants, Fruits and Fresh Vegetables,'
          + 'Organics, exotic English vegetables, Cashew, nuts, dry fruits, Curry leaves, Banana, Green Chilies, All'
          + 'spices, Coconut, Sugar, edible and essential Oils, Eggs, Maize, seedlings, flowers, plants and more'
      }
    ]);
  }

 

  onFilterChange() {

    this.count = 0;
    this.previousCount = 0;
    this.listings = [];
    this.noMoreListing = false;
    this.getListings();
  }

  getListings() {
    if (this.noMoreListing === false) {
      this.loading = true;
      this.error = false;

      const listing = Parse.Object.extend('ff_newlisting');
      const query = new Parse.Query(listing);

      if (this.search_filter === 'buy') {
        query.equalTo('list_type', 'B');
      } else if (this.search_filter === 'sell') {
        query.equalTo('list_type', 'S');
      } else if (this.search_filter === 'tender') {
        query.equalTo('list_type', 'T');
      }

      if (this.search_query) {
        mixpanel.track(environment.MIXPANEL_TRACK_PREFIX + 'Searched');
        this.search_filter = 'all';
        query.matches('list_title', this.search_query, 'i');
      }
      query.equalTo('active', true);
      query.descending('createdAt');
      // debugger; 
      query.limit(this.limit);
      query.skip(this.count * this.limit);
      query.find().then((results) => {
        if (results.length === 0) {
          this.noMoreListing = true;
        }
        this.listings = this.listings.concat(results);
        this.loading = false;
        this.error = false;
        console.log('getListings() => results', results);
      }, (error) => {
        this.loading = false;
        this.error = true;

        console.log(error);
      });
      this.count++;
    }

  }



  query_from_segment(segment) {
    let query = segment.replace('.html', '');
    query = query.replace(/-/g, ' ');
    return query;
  }

  addSearchQuery(query) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json; charset=utf-8');
    headers.append('X-Parse-Application-Id', environment.PARSE_APP_ID);
    headers.append('X-Parse-REST-API-Key', environment.PARSE_REST_API_KEY);

    this.http.post(this.system.generateRequestUrl('/classes/ff_search', null, environment.serverURL),
      JSON.stringify({ query: query, active: true }), { headers: headers })
      .map(res => res.json()).subscribe(response => { }, error => { });
  }

  searchListing() {
    console.log("searchListing(): called");
    this.listings = [];
    this.noMoreListing = false;
    this.count = 0;
    this.previousCount = 0;

    if (this.search_filter === 'sell') {
      this.router.navigate(
        ['products/sell/' + this.system.seo_url_generate('', this.search_query, '')],
        {
          queryParams: { rf: new Date().getTime() }
        }
      );
    } else if (this.search_filter === 'buy') {
      this.router.navigate(
        ['products/buy/' + this.system.seo_url_generate('', this.search_query, '')],
        {
          queryParams: { rf: new Date().getTime() }
        }
      );
    } else if (this.search_filter === 'tender') {
      this.router.navigate(
        ['products/buy/' + this.system.seo_url_generate('', this.search_query, '')],
        {
          queryParams: { rf: new Date().getTime() }
        }
      );
    } else {
      this.router.navigate(
        ['products/all/' + this.system.seo_url_generate('', this.search_query, '')],
        {
          queryParams: { rf: new Date().getTime() }
        }
      );
    }
    this.addSearchQuery(this.search_query);
    this.getListings();
  }

}
