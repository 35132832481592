import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { SystemService } from '../../../system/system.service';
import { environment } from '../../../../environments/environment';

import { CookieService } from 'ngx-cookie-service';

import * as Parse from 'parse';
import * as mixpanel from 'mixpanel-browser';

import 'rxjs/add/operator/map';

@Component({
  selector: 'app-process',
  templateUrl: './process.component.html',
  styleUrls: ['./process.component.scss']
})
export class ProcessComponent implements OnInit {

  user = {
    username: null,
    name: null,
    email: null,
    password: '123456',
    mobile: null,
    isReferralEligible: true
  };

  first_time = false;
  loading = true;
  action_type = null;

  signupForm: FormGroup;


  constructor(
    private http: Http,
    private route: ActivatedRoute,
    private router: Router,
    public system: SystemService,
    private cookie: CookieService,
    private title: Title,
    private meta: Meta,
    private formBuilder: FormBuilder,
  ) {
    Parse.initialize(environment.PARSE_APP_ID, environment.PARSE_JS_KEY);
    (Parse as any).serverURL = environment.serverURL;

    if (window.location.hash && window.location.hash === '#_=_') {
      console.log('Windows Location Hash: ', window.location.hash);
      if (window.history && history.pushState) {
        window.history.pushState('', document.title, window.location.pathname + window.location.search);
      } else {
        // Prevent scrolling by storing the page's current scroll offset
        const scroll = {
          top: document.body.scrollTop,
          left: document.body.scrollLeft
        };
        window.location.hash = '';
        // Restore the scroll offset, should be flicker free
        document.body.scrollTop = scroll.top;
        document.body.scrollLeft = scroll.left;
      }
    }
    mixpanel.init(environment.MIXPANEL_TOKEN);

  }

  ngOnInit() {
    this.title.setTitle('Processing...');

    this.signupForm = this.formBuilder.group({
      'name': ['', [Validators.required]],
      'mobile': ['', [Validators.required, Validators.pattern('[+]?[0-9]*')]],
      'none': []
    });

    this.route.queryParams.subscribe(params => {
      console.log(params);
      this.action_type = this.route.snapshot.paramMap.get('type');
      if (this.route.snapshot.paramMap.get('type') === 'phone') {
        if (this.route.snapshot.paramMap.get('for') === 'signup') {
          if (typeof params.status !== 'undefined' && params.status === 'NOT_AUTHENTICATED') {
            this.router.navigate(['account/signup']);
          }
        } else if (this.route.snapshot.paramMap.get('for') === 'signin') {
          if (typeof params.status !== 'undefined' && params.status === 'NOT_AUTHENTICATED') {
            this.router.navigate(['account/signin']);
          }
        }

        const users = Parse.Object.extend('User');

        const query = new Parse.Query(users);

        query.equalTo('mobile', '+' + params.phoneNumber);
        query.find().then((results) => {
          console.log(results);
          if (results.length > 0) {
            this.system.USER = results[0];

            // console.log("phone loginnnn......",results[0]['id']);
debugger;
            this.cookie.set(
              'user',
              results[0]['id'],
              new Date(new Date().setDate(new Date().getDate() + 365)),
              '/',
              '.' + environment.APP_URL,
              environment.COOKIE_SECURE,
              'Lax'
            );
            // console.log("get user.....",this.cookie.get('user'));

            this.system.IS_LOGIN = true;

            this.mxTrackUserAction(
              results[0].attributes.username,
              results[0].attributes.name,
              (typeof (results[0].attributes.email) !== 'undefined') ? results[0].attributes.email : null,
              (typeof (results[0].attributes.mobile) !== 'undefined') ? results[0].attributes.mobile : null
            );

            if (typeof params.state !== 'undefined') {
              const state = JSON.parse(params.state);
              if (typeof state.next !== undefined) {
                console.log('account/oauth/process: phone - state.next', state.next);
                console.log('account/oauth/process: phone - decodeURIComponent(state.next)', decodeURIComponent(state.next));

                this.router.navigateByUrl(decodeURIComponent(state.next));
              } else {
                if(results[0].attributes.user_type === 'BB')
                {
                  this.router.navigate(['tender/dashboard']);
                }
                else
                {
                  this.router.navigate(['account/profile']);
                }
              }
            } else {
              if(results[0].attributes.user_type === 'BB')
                {
                  this.router.navigate(['tender/dashboard']);
                }
                else
                {
                  this.router.navigate(['account/profile']);
                }
            }
          } else {
            this.user.mobile = '+' + params.phoneNumber;
            this.first_time = true;
            this.loading = false;
          }
        });

      } else if (this.route.snapshot.paramMap.get('type') === 'fb') {
        this.http.get(this.system.generateRequestUrl('/v3.2/oauth/access_token', {
          client_id: environment.FACEBOOK_APP_ID,
          client_secret: environment.FACEBOOK_CLIENT_SECRET,
          code: params.code,
          redirect_uri: environment.FACEBOOK_REDIRECT_URL + '/signin/fb/'
        }, environment.FACEBOOK_API_URL))
          .map(res => res.json()).subscribe(response => {
            /*
              {
                "access_token": {access-token},
                "token_type": {type},
                "expires_in":  {seconds-til-expiration}
              }
             */
            console.log('response', response);

            this.http.get(this.system.generateRequestUrl('/v3.2/me/', {
              access_token: response.access_token,
              fields: 'id,name,email'
            }, environment.FACEBOOK_API_URL))
              .map(res => res.json()).subscribe(resp => {
                console.log('resp', resp);
                /*
                    {
                      id: "1234512345123451",
                      phone: {
                        number: "+15551234567"
                        country_prefix: "1",
                        national_number: "5551234567"
                      },
                      application: {
                        id: "5432154321543210"
                      }
                    }
                 */

                const users = Parse.Object.extend('User');

                const query = new Parse.Query(users);

                query.equalTo('email', resp.email);
                query.find().then((results) => {
                  console.log(results[0]);
                  if (results.length > 0) {
                    this.system.USER = results[0];
                    this.cookie.set(
                      'user',
                      results[0]['id'],
                      new Date(new Date().setDate(new Date().getDate() + 365)),
                      '/',
                      '.' + environment.APP_URL,
                      environment.COOKIE_SECURE,
                      'Lax'
                    );

                    this.mxTrackUserAction(
                      results[0].attributes.username,
                      results[0].attributes.name,
                      (typeof (results[0].attributes.email) !== 'undefined') ? results[0].attributes.email : null,
                      (typeof (results[0].attributes.mobile) !== 'undefined') ? results[0].attributes.mobile : null
                    );

                    // mixpanel.people.get();

                    this.system.IS_LOGIN = true;
                    if (typeof params.state !== 'undefined') {
                      console.log("login 1");
                      
                      const state = JSON.parse(params.state);
                      if (typeof state.next !== undefined) {
                        console.log("login 2");
                        console.log('account/oauth/process: phone - state.next', state.next);
                        console.log('account/oauth/process: phone - decodeURIComponent(state.next)', decodeURIComponent(state.next));

                        this.router.navigateByUrl(decodeURIComponent(state.next));
                      } else {
                        this.router.navigate(['account/profile']);
                      }
                    } else {
                      this.router.navigate(['account/profile']);
                    }
                  } else {
                    this.user.name = resp.name;
                    this.user.email = resp.email;
                    this.first_time = true;
                    this.loading = false;
                  }
                });

              });
          });
      }
    });
  }

  createUser() {
    this.validateAllFormFields(this.signupForm);

    if (this.signupForm.valid) {
      this.user.username = this.user.mobile;
      const headers = new Headers();
      headers.append('Content-Type', 'application/json; charset=utf-8');
      headers.append('X-Parse-Application-Id', environment.PARSE_APP_ID);
      headers.append('X-Parse-REST-API-Key', environment.PARSE_REST_API_KEY);

      this.http.post(this.system.generateRequestUrl('/classes/_User', null, environment.serverURL),
        JSON.stringify(this.user), { headers: headers })
        .map(res => res.json()).subscribe(response => {
          this.cookie.set(
            'user',
            response.objectId,
            new Date(new Date().setDate(new Date().getDate() + 365)),
            '/',
            '.' + environment.APP_URL,
            environment.COOKIE_SECURE,
            'Lax'
          );
          this.system.IS_LOGIN = true;

          this.system.USER = response;
          this.router.navigate(['account/profile']);

        }, error => {

        });
    }
  }

  mxTrackUserAction(username, name, email, mobile) {
    mixpanel.identify(username);
    mixpanel.people.set('$name', name);
    mixpanel.people.set('$email', email);
    mixpanel.people.set('$phone', mobile);
  }

  private validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
