import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Http, Headers } from '@angular/http';

import { environment } from '../../../environments/environment';
import { SystemService } from '../../system/system.service';

import * as Parse from 'parse';
import { stringify } from 'querystring';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  user = {
    objectId: null,
    name: null,
    mobile: null,
    email: null,
    city: null,
    state: null,
    country: null,
    pin: null,
    id_proof: null,
    address_proof: null,
    bus_proof: null,
    user_type: null,
  };
  count = 0;
  limit = 100;

  listings = [];
  loading = true;
  error = false;

  search_query = null;
  search_filter = 'all';

  fileToUpload: File = null;
  idProofSrc = null;
  addressProofSrc = null;
  businessProofSrc = null;
  options = 'form';
  buyerView = "list";
  sellerView = "list";
  BidArray: any[] = [];
  bids: any[] = [];
  currentItem: any;
  currentBid: any;
  submittedOffers: any[] = [];
  currentSellerOffer = {
    offer: {
      list_id: null,
      bidder_id: null,
      o_qty: null,
      o_price: null,
      o_date_of_delivery: null,
      o_farm_address: null,
      o_comments: null,
      o_submitted_date: null,
      o_status: 'S'
    },
    listing: null,
    offerId: null

  };
  currentBidId: any = null;

  constructor(private http: Http, private cookie: CookieService, public system: SystemService, private router: Router,
    private title: Title, private meta: Meta) { }

  ngOnInit() {
    this.title.setTitle('My Profile');

    // un comment for buyer mode Sreeraj karippala
    // this.cookie.set('user','wvCVg5DMtN'); 

    //un comment for seller Mode Ananthu
    //  this.cookie.set('user','5mkV2VluaG'); 

    // console.log("set cookieee",this.cookie.get('user'));


    if (this.cookie.get('user')) {
      const headers = new Headers();

      headers.append('X-Parse-Application-Id', environment.PARSE_APP_ID);
      headers.append('X-Parse-REST-API-Key', environment.PARSE_REST_API_KEY);

      this.http.get(this.system.generateRequestUrl('/classes/_User/' + this.cookie.get('user'),
        {}, environment.serverURL), { headers: headers })
        .map(res => res.json()).subscribe(response => {
          console.log(response);
          this.user = response;
          if (this.user.user_type === 'BB' || this.user.user_type === 'CF') {
            this.system.IS_PREMIUM = true;
          }
        });

      Parse.initialize(environment.PARSE_APP_ID, environment.PARSE_JS_KEY);
      (Parse as any).serverURL = environment.serverURL;

      const listing = Parse.Object.extend('ff_newlisting');
      const query = new Parse.Query(listing);

      if (this.search_filter === 'buy') {
        query.equalTo('list_type', 'B');
      } else if (this.search_filter === 'sell') {
        query.equalTo('list_type', 'S');
      } else if (this.search_filter === 'tender') {
        query.equalTo('list_type', 'T');
      }

      if (this.search_query) {
        query.matches('list_title', this.search_query, 'i');
      }

      query.equalTo('listedBy', new Parse.Object('_User', { 'objectId': this.cookie.get('user') }));

      query.descending('createdAt');
      query.find().then((results) => {
        this.listings = results;
        this.loading = false;
        this.error = false;
        console.log(results);
      }, (error) => {
        this.loading = false;
        this.error = true;

        console.log(error);
      });
    }
    // this.getProductListings();
    // this.getUserSubmittedOffers();
  }


  // getProductListings() {

  //     const listing = Parse.Object.extend('ff_newlisting');
  //     const queryList = new Parse.Query(listing);

  //     // if (this.search_filter === 'buy') {
  //     //   query.equalTo('list_type', 'B');
  //     // } else if (this.search_filter === 'sell') {
  //     //   query.equalTo('list_type', 'S');
  //     // }

  //     // if (this.search_query) {
  //     //   mixpanel.track(environment.MIXPANEL_TRACK_PREFIX + 'Searched');
  //     //   this.search_filter = 'all';
  //     //   query.matches('list_title', this.search_query, 'i');
  //     // }

  //     // Assume Parse.Object myPost was previously created.

  //     // queryList.equalTo('active', true);
  //     queryList.equalTo('listedBy', new Parse.Object('_User', { 'objectId': this.cookie.get('user') }));
  //     queryList.descending('createdAt');
  //     queryList.limit(this.limit);
  //     queryList.skip(this.count * this.limit);
  //     queryList.find().then((results) => {

  //     results.forEach(element => {
  //       this.getListingOffers(element,this.user.user_type);
  //     });
  //       // this.listings = this.listings.concat(results);
  //     }, (error) => {
  //       console.log(error);
  //     });
  //     console.log(this.BidArray);
  // }

  // getListingOffers(element,type)
  // {
  //   this.BidArray = [];
  //   let qty = element.attributes.list_qty;
  //   let title = element.attributes.list_title;
  //   let id = element.attributes.list_id;

  //   console.log("type",type);
  //   let attributes = [];
  //   let measure= 0 ;
  //   let amount = '0';
  //   const Offers = Parse.Object.extend('ff_offers');
  //   const queryOffer = new Parse.Query(Offers);
  //   queryOffer.equalTo('list_id',id);
  //   queryOffer.find().then((res: any)=>{
  //     console.log("ff_offers()=>",res);

  //   if(res.length > 0)
  //   {     
  //     res.forEach(element => {
  //     attributes.push({"bid":element.attributes,"id":element.id});
  //     amount = element.attributes.o_qty;
  //     measure = measure + parseInt(amount.slice(0, -2)); 
  //     });

  //     if(type === 'BB')
  //     {
  //       const Tenders = Parse.Object.extend('ff_tenders');
  //       const queryTender = new Parse.Query(Tenders);
  //       queryTender.equalTo('list_id',id);
  //       queryTender.find().then((res: any)=>{
  //       console.log("tenderrrrrr",res[0].objectId);

  //       this.BidArray.push({'title':title,'measure': measure,'count':attributes.length,'bids':attributes,'listing':element.attributes,'tender': res[0].attributes});
  //       });
  //     }
  //     else
  //     {
  //       this.BidArray.push({'title':title,'measure': measure,'count':attributes.length,'bids':attributes,'listing':element.attributes,'tender': null});
  //     }
  //   }
  //    })
  // }


  // bidStatus(opt)
  // {
  //   let update = {"o_status": null}
  //   if(opt === 'accept')
  //   {
  //    update.o_status = 'A'
  //   }
  //   if(opt === 'reject')
  //   {
  //     update.o_status = 'R'
  //   }

  //   console.log("current Bid",this.currentBidId);

  //   const headers = new Headers();
  //   headers.append('Content-Type', 'application/json; charset=utf-8');
  //   headers.append('X-Parse-Application-Id', environment.PARSE_APP_ID);
  //   headers.append('X-Parse-REST-API-Key', environment.PARSE_REST_API_KEY);

  //   this.http.put(this.system.generateRequestUrl('/classes/ff_offers/' + this.currentBidId, null, environment.serverURL),
  //     JSON.stringify(update), { headers: headers })
  //     .map(res => res.json()).subscribe(response => {
  //       console.log(response);
  //       this.getProductListings();
  //     }, error => {

  //     });
  // }



  // async getUserSubmittedOffers()
  // {
  //   this.submittedOffers = [];
  //   const Offer = Parse.Object.extend('ff_offers');
  //   const queryOffer = new Parse.Query(Offer);
  //   queryOffer.equalTo('bidder_id', new Parse.Object('_User', { 'objectId': this.cookie.get('user') }));
  //   queryOffer.find().then((res: any)=>{
  //   console.log("offerrrrrrrr",res);
  //   res.forEach(element => { 
  //   this.getListingItem(element);
  //   });

  //   });
  //   console.log("submit offers",this.submittedOffers);

  // }

  // getListingItem(element)
  // {
  //   const list = Parse.Object.extend('ff_newlisting');
  //   const queryList = new Parse.Query(list);
  //   queryList.equalTo('list_id',element.attributes.list_id);
  //   queryList.find().then((res: any)=>{
  //     console.log("list itemmmmmmmmmm",res);
  //     this.submittedOffers.push({'offer':element.attributes,'listing':res[0].attributes,'offerId':element.id});
  //   });

  // }

  // updateOfferData() {

  //   const headers = new Headers();
  //   headers.append('Content-Type', 'application/json; charset=utf-8');
  //   headers.append('X-Parse-Application-Id', environment.PARSE_APP_ID);
  //   headers.append('X-Parse-REST-API-Key', environment.PARSE_REST_API_KEY);

  //   this.http.put(this.system.generateRequestUrl('/classes/ff_offers/' + this.currentSellerOffer.offerId, null, environment.serverURL),
  //     JSON.stringify(this.currentSellerOffer.offer), { headers: headers })
  //     .map(res => res.json()).subscribe(response => {
  //       console.log(response);
  //       this.getUserSubmittedOffers();
  //     }, error => {

  //     });
  // }


  saveUser() {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json; charset=utf-8');
    headers.append('X-Parse-Application-Id', environment.PARSE_APP_ID);
    headers.append('X-Parse-REST-API-Key', environment.PARSE_REST_API_KEY);

    this.http.post(this.system.generateRequestUrl('/functions/UserUpdate', null, environment.serverURL),
      JSON.stringify({
        user_id: this.cookie.get('user'),
        email: this.user.email,
        mobile: this.user.mobile,
        city: this.user.city,
        state: this.user.state,
        country: this.user.country,
        pin: this.user.pin,
        id_proof: this.user.id_proof,
        address_proof: this.user.address_proof,
        bus_proof: this.user.bus_proof
      }), { headers: headers })
      .map(res => res.json()).subscribe(response => {
        alert('Changes Saved');
      }, error => {
        alert('Error: Something went wrong, please try again later.');
      });
  }

  // changeView(opt: string,item)
  //   {
  //     this.buyerView = opt;

  //     if(item !== null)
  //     {
  //     console.log("itemmmmmssssssss",item.bids);
  //     this.bids = item.bids;
  //     this.currentItem = item;
  //     }

  //   }

  //   viewDetails(opt,bid,id)
  //   {
  //     this.buyerView = opt;
  //       console.log("sssssss",bid,this.currentItem);
  //       this.currentBid = bid;
  //       this.currentBidId = id;

  //   }

  //   changeSellerView(opt: string,item)
  //   {
  //     this.sellerView = opt;
  //     this.currentSellerOffer = {
  //       offer : {
  //       list_id : item.offer.list_id,
  //       bidder_id : { __type: 'Pointer', className: '_User', objectId: this.user.objectId},
  //       o_qty: item.offer.o_qty,
  //       o_price:item.offer.o_price,
  //       o_date_of_delivery: item.offer.o_date_of_delivery,
  //       o_farm_address : item.offer.o_farm_address,
  //       o_comments : item.offer.o_comments,
  //       o_submitted_date : item.offer.o_submitted_date,
  //       o_status : item.offer.o_status
  //       },
  //       listing : item.listing,
  //       offerId: item.offerId
  //     }
  //   }

  handleFileInput(files: FileList, proof) {
    this.fileToUpload = files.item(0);
    console.log(this.fileToUpload);
    const file = files[0];

    const reader = new FileReader();

    if (proof === 'id') {
      reader.onload = e => this.idProofSrc = reader.result;
    } else if (proof === 'address') {
      reader.onload = e => this.addressProofSrc = reader.result;
    } else if (proof === 'business') {
      reader.onload = e => this.businessProofSrc = reader.result;
    }



    // reader.readAsDataURL(file);

    reader.onloadend = (e) => {
      console.log();
      const headers = new Headers();
      headers.append('X-Parse-Application-Id', environment.PARSE_APP_ID);
      headers.append('X-Parse-REST-API-Key', environment.PARSE_REST_API_KEY);
      headers.append('Content-Type', this.fileToUpload.type);

      this.http.post(this.system.generateRequestUrl('/functions/UserProof', null, environment.serverURL),
        JSON.stringify({
          user_id: this.cookie.get('user'),
          type: proof,
          file_name: this.fileToUpload.name,
          file_data: (reader.result as string).split(',')[1],
          file_type: this.fileToUpload.type,
        }), { headers: headers })
        .map(res => res.json()).subscribe(response => {
          alert('Changes Saved');
        }, error => {
          alert('Error: Something went wrong, please try again later.');
        });
    };
    reader.readAsDataURL(file);

    // this.http.post(this.system.generateRequestUrl('/files/' + this.fileToUpload.name, null, environment.serverURL),
    //   this.fileToUpload, { headers: headers })
    //   .map(res => res.json()).subscribe(uploaded_file => {
    //     headers.append('Content-Type', 'application/json; charset=utf-8');
    //     console.log(uploaded_file);
    //     let proof_file = null;
    //     if (proof === 'id') {
    //       proof_file = JSON.stringify({
    //         user_id: this.cookie.get('user'),
    //         email: this.user.email,
    //         mobile: this.user.mobile,
    //         city: this.user.city,
    //         state: this.user.state,
    //         country: this.user.country,
    //         pin: this.user.pin,
    //         id_proof: {
    //           __type: 'File',
    //           name: uploaded_file.name,
    //           url: uploaded_file.url
    //         },
    //         address_proof: this.user.address_proof,
    //         bus_proof: this.user.bus_proof
    //       });
    //     } else if (proof === 'address') {
    //       proof_file = JSON.stringify({
    //         user_id: this.cookie.get('user'),
    //         email: this.user.email,
    //         mobile: this.user.mobile,
    //         city: this.user.city,
    //         state: this.user.state,
    //         country: this.user.country,
    //         pin: this.user.pin,
    //         id_proof: this.user.id_proof,
    //         address_proof: {
    //           __type: 'File',
    //           name: uploaded_file.name
    //         },
    //         bus_proof: this.user.bus_proof
    //       });
    //     } else if (proof === 'business') {
    //       proof_file = JSON.stringify({
    //         user_id: this.cookie.get('user'),
    //         email: this.user.email,
    //         mobile: this.user.mobile,
    //         city: this.user.city,
    //         state: this.user.state,
    //         country: this.user.country,
    //         pin: this.user.pin,
    //         id_proof: this.user.id_proof,
    //         address_proof: this.user.address_proof,
    //         bus_proof: {
    //           __type: 'File',
    //           name: uploaded_file.name,
    //           url: uploaded_file.url
    //         },
    //       });
    //     }
    //     this.http.post(this.system.generateRequestUrl('/functions/UserUpdate', null, environment.serverURL),
    //       proof_file, { headers: headers })
    //       .map(res => res.json()).subscribe(response => {
    //         alert('Changes Saved');
    //       }, error => {
    //         alert('Error: Somthing went wrong, please try again later.');
    //       });
    //   });
  }

}
