import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Http, Headers } from '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';

import { environment } from '../../../environments/environment';
import { SystemService } from '../../system/system.service';

declare const Razorpay: any;
import * as Parse from 'parse';
import * as mixpanel from 'mixpanel-browser';


@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {

  plans = [];
  planDetails = null;
  pageView = 'planDetails';
  user = null;
  next = '/home';
  enableNext = false;

  constructor(
    private http: Http,
    private cookie: CookieService,
    private route: ActivatedRoute,
    private router: Router,
    public system: SystemService,
    private title: Title,
    private meta: Meta
  ) {
    mixpanel.init(environment.MIXPANEL_TOKEN);
  }

  ngOnInit() {
    Parse.initialize(environment.PARSE_APP_ID, environment.PARSE_JS_KEY);
    (Parse as any).serverURL = environment.serverURL;

    this.route.queryParams.subscribe(params => {
      if (typeof params.next !== 'undefined') {
        console.log('account/subscription: this.route.queryParams -> params.next', params.next);
        this.next = params.next;
        console.log('account/subscription: this.next', this.next);

      }
    });

    if (this.cookie.get('user')) {
      const headers = new Headers();
      headers.append('X-Parse-Application-Id', environment.PARSE_APP_ID);
      headers.append('X-Parse-REST-API-Key', environment.PARSE_REST_API_KEY);

      this.http.get(this.system.generateRequestUrl('/classes/_User/' + this.cookie.get('user'),
        {}, environment.serverURL), { headers: headers })
        .map(res => res.json()).subscribe(response => {
          console.log(response);
          this.user = response;
          this.route.queryParams.subscribe(params => {
            if (typeof params.payment_id !== 'undefined') {
              this.pageView = 'paymentComplete';

              this.http.post(this.system.generateRequestUrl('/functions/PaymentDetails', null, environment.serverURL),
                JSON.stringify({
                  payment_id: params.payment_id,
                }), { headers: headers })
                .map(res => res.json()).subscribe(res => {
                  console.log(res);
                  this.next = typeof res.result.data.notes.next !== 'undefined' ? decodeURIComponent(res.result.data.notes.next) : '/home';
                  this.updateUserSubscriprion(res.result.data.notes.user_id, res.result.data.notes.days);
                  mixpanel.track(environment.MIXPANEL_TRACK_PREFIX + 'Subscription Success');
                  this.enableNext = true;
                }, error => {
                  mixpanel.track(environment.MIXPANEL_TRACK_PREFIX + 'Payment Registration Failed');
                  alert('Error: Something went wrong, please try again later.');
                });
            }
          });
        });
    }
  }

  updateUserSubscriprion(user_id, days) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json; charset=utf-8');
    headers.append('X-Parse-Application-Id', environment.PARSE_APP_ID);
    headers.append('X-Parse-REST-API-Key', environment.PARSE_REST_API_KEY);

    this.http.post(this.system.generateRequestUrl('/functions/UserSubscriptionUpdate', null, environment.serverURL),
      JSON.stringify({
        user_id: user_id,
        sub_start: this.dayOfYear(),
        sub_end: this.dayOfYear() + parseFloat(days),
      }), { headers: headers })
      .map(res => res.json()).subscribe(response => {
        console.log(response);
      }, error => {
        alert('Error: Something went wrong, please try again later.');
      });
  }

  dayOfYear() {
    const now: any = new Date();
    const start: any = new Date(now.getFullYear(), 0, 0);
    const diff = (now - start) + ((start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000);
    const oneDay = 1000 * 60 * 60 * 24;
    console.log('Day of Year: ' + Math.floor(diff / oneDay));

    return Math.floor(diff / oneDay);
  }

  gotoNext() {
    this.router.navigateByUrl(decodeURIComponent(this.next));
  }
}
